// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-p-amici-di-gallenga-tsx": () => import("./../../../src/pages/it/p/amici_di_gallenga.tsx" /* webpackChunkName: "component---src-pages-it-p-amici-di-gallenga-tsx" */),
  "component---src-pages-it-p-aneddoti-tsx": () => import("./../../../src/pages/it/p/aneddoti.tsx" /* webpackChunkName: "component---src-pages-it-p-aneddoti-tsx" */),
  "component---src-pages-it-p-bio-tsx": () => import("./../../../src/pages/it/p/bio.tsx" /* webpackChunkName: "component---src-pages-it-p-bio-tsx" */),
  "component---src-pages-it-p-boston-1836-tsx": () => import("./../../../src/pages/it/p/boston1836.tsx" /* webpackChunkName: "component---src-pages-it-p-boston-1836-tsx" */),
  "component---src-pages-it-p-canada-1842-tsx": () => import("./../../../src/pages/it/p/canada1842.tsx" /* webpackChunkName: "component---src-pages-it-p-canada-1842-tsx" */),
  "component---src-pages-it-p-castellamonte-1774-tsx": () => import("./../../../src/pages/it/p/castellamonte1774.tsx" /* webpackChunkName: "component---src-pages-it-p-castellamonte-1774-tsx" */),
  "component---src-pages-it-p-chi-siamo-tsx": () => import("./../../../src/pages/it/p/chi_siamo.tsx" /* webpackChunkName: "component---src-pages-it-p-chi-siamo-tsx" */),
  "component---src-pages-it-p-corsica-1831-tsx": () => import("./../../../src/pages/it/p/corsica1831.tsx" /* webpackChunkName: "component---src-pages-it-p-corsica-1831-tsx" */),
  "component---src-pages-it-p-firenze-1888-tsx": () => import("./../../../src/pages/it/p/firenze1888.tsx" /* webpackChunkName: "component---src-pages-it-p-firenze-1888-tsx" */),
  "component---src-pages-it-p-galles-1895-tsx": () => import("./../../../src/pages/it/p/galles1895.tsx" /* webpackChunkName: "component---src-pages-it-p-galles-1895-tsx" */),
  "component---src-pages-it-p-i-blackgown-papers-tsx": () => import("./../../../src/pages/it/p/i_blackgown_papers.tsx" /* webpackChunkName: "component---src-pages-it-p-i-blackgown-papers-tsx" */),
  "component---src-pages-it-p-istanbul-1875-tsx": () => import("./../../../src/pages/it/p/istanbul1875.tsx" /* webpackChunkName: "component---src-pages-it-p-istanbul-1875-tsx" */),
  "component---src-pages-it-p-londra-1839-tsx": () => import("./../../../src/pages/it/p/londra1839.tsx" /* webpackChunkName: "component---src-pages-it-p-londra-1839-tsx" */),
  "component---src-pages-it-p-londra-1843-tsx": () => import("./../../../src/pages/it/p/londra1843.tsx" /* webpackChunkName: "component---src-pages-it-p-londra-1843-tsx" */),
  "component---src-pages-it-p-londra-1849-tsx": () => import("./../../../src/pages/it/p/londra1849.tsx" /* webpackChunkName: "component---src-pages-it-p-londra-1849-tsx" */),
  "component---src-pages-it-p-luoghi-tsx": () => import("./../../../src/pages/it/p/luoghi.tsx" /* webpackChunkName: "component---src-pages-it-p-luoghi-tsx" */),
  "component---src-pages-it-p-parma-1810-tsx": () => import("./../../../src/pages/it/p/parma1810.tsx" /* webpackChunkName: "component---src-pages-it-p-parma-1810-tsx" */),
  "component---src-pages-it-p-parma-1848-tsx": () => import("./../../../src/pages/it/p/parma1848.tsx" /* webpackChunkName: "component---src-pages-it-p-parma-1848-tsx" */),
  "component---src-pages-it-p-russia-1882-tsx": () => import("./../../../src/pages/it/p/russia1882.tsx" /* webpackChunkName: "component---src-pages-it-p-russia-1882-tsx" */),
  "component---src-pages-it-p-sicilia-1860-tsx": () => import("./../../../src/pages/it/p/sicilia1860.tsx" /* webpackChunkName: "component---src-pages-it-p-sicilia-1860-tsx" */),
  "component---src-pages-it-p-tangeri-1834-tsx": () => import("./../../../src/pages/it/p/tangeri1834.tsx" /* webpackChunkName: "component---src-pages-it-p-tangeri-1834-tsx" */),
  "component---src-pages-it-p-torino-1833-tsx": () => import("./../../../src/pages/it/p/torino1833.tsx" /* webpackChunkName: "component---src-pages-it-p-torino-1833-tsx" */)
}

